.spacer {
    flex: 1;
}

.navbar {
    position: fixed;
    width: 100%;
    background: var(--grey-blue);
    height: 56px;
    top: 0;
    left: 0;
    z-index:1;
}

.navbarBody {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.navbarLogo {
    margin-left: 1rem;
}

.navbarLogo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
}

.navbarItems ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.navbarItems li {
    padding: 0 0.5rem;
}

.navbarItems a {
    color: white;
    text-decoration: none;

}

.navbarItems a:hover,
.navbarItems a:active {
    font-weight: bold;
}

.active {
    font-weight: bold;
}

@media (max-width: 768px) {
    .navbarItems {
        display: none;
    }
}

@media (min-width: 769px) {
    .navbarToggleButton {
        display: none;
    }

    .navbarLogo {
        margin-left: 0;
    }
}