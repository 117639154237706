.sideDrawer {
  height: 100vh;
  background: var(--grey-blue);
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.sideDrawerContentContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.sideDrawer.open {
  transform: translateX(0);
}

.sideDrawer ul {
  list-style: none;
  text-align: left;
  padding-right: 5px;
  padding-top: 50px;
}

.sideDrawer li {
  margin: 0.5rem 0;
  padding-top: 20px;
}

.sideDrawer a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.sideDrawer a:hover,
.sideDrawer a:active {
  color: orange;
  cursor: pointer;
}

.social {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
  padding-bottom: 80px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.iconSize {
  height: 44px;
  width: 44px;
}

@media (min-width: 769px) {
  .sideDrawer {
    display: none;
  }
}
