/*structural styles*/
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 80px;
  padding-left: 80px;
}

.imgCol {
  display: flex;
  flex: 2 1 450px;
}

.image {
  max-width: 100%;
  object-fit: contain;
}

.verticalStack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 300px;
  padding-right: 150px;
}

/*font styles*/

/*.cardLink a {*/
/*  font-family: var(--san-serif-font);*/
/*  font-size: 19px;*/
/*  line-height: 1.67;*/
/*  text-align: left;*/
/*  color: var(--warm-grey);*/
/*  text-decoration: none;*/
/*}*/

.cardLink {
  margin-bottom: 60px;
  margin-top: 30px;
}

.headingFont {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: left;
  color: var(--black);
  text-transform: uppercase;
  padding-bottom: 10px;
}

.blurbFont {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 10px;
}

@media only screen and (max-width: 790px) {
  .verticalStack {
    flex: 1 1 auto;
    padding-right: 0px;
    padding-bottom: 30px;
  }
  .image {
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
  .wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }
  /*.imageCol {*/
  /*  !*justify-content: center;*!*/
  /*  !*align-self: center;*!*/
  /*}*/
}
