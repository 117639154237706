@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quattrocento&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quattrocento+Sans&display=swap");

:root {
  --pastel-red: #df6057;
  --grey-blue: #619da3;
  --plum-purple: #4d0547;
  --black: #000000;
  --warm-grey: #918e8e;
  --color-heading-size: 20px;
  --san-serif-font: Montserrat, sans-serif;
  --serif-font: Quattrocento, serif;
  --san-serif-Quat: Quattrocento Sans, sans-serif;
  --mobile-breakpoint: 790px;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--san-serif-font), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  height: 100vh;
}

html {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}
a[onclick], a:not([name]) { cursor: pointer }

/**************** Layout and structure styles **********************************/
.container-flex {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.top-div {
  margin-top: 100px;
}
/************** Heading Styles ************************/

.landing-header {
  font-family: var(--san-serif-font);
  font-size: 32px;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
  color: var(--plum-purple);
}

.color-main-heading {
  opacity: 0.29;
  font-family: var(--serif-font);
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  text-align: left;
}

.color-sub-heading {
  font-family: var(--serif-font);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  text-align: left;
}

.small-header {
  height: 16px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: left;
  color: var(--black);
  text-transform: uppercase;
}

/* adding this class here again fixes active class not showing when first landing on home page
this class is also defined in the Navbar styles*/
.active {
  font-weight: bold;
}

/***************** Colors *************************************/

.red {
  color: var(--pastel-red);
}

.green {
  color: var(--grey-blue);
}

.purple {
  color: var(--plum-purple);
}

/*****************************************************/
