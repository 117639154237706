.verticalStack {
    display: flex;
    flex-direction: column;
}

.quoteSection {
    position: relative;
    height: 100px;
    background-color: pink;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.quoteHeader {
    font-family: var(--san-serif-font);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: 2.04px;
    color: var(--black);
    text-transform: uppercase;
    position: absolute;
    left: 45px;
    top: -5px;
}

.spacer {
    margin-top: 100px;
}