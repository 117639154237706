.itineraryContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.verticalStack {
  display: flex;
  flex-direction: column;
}

.headingFont {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: center;
  color: var(--black);
  text-transform: uppercase;
  padding-bottom: 10px;
}

.subText {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 10px;
    color: var(--grey-blue);
    margin-bottom: 50px;
}

.spaceBelow {
    margin-bottom: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

}