.spacer {
    margin-top: 100px;
}

.divSpacer {
    padding-top: 40px;
    padding-bottom: 10px;
}

.verticalStack {
    display: flex;
    flex-direction: column;
}

.btnSpacer {
    margin: 50px auto 0 auto;
}

.introBlurbFont {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: var(--plum-purple);
  margin-bottom: 60px;
}

.personHeaderFont {
  font-family: var(--san-serif-font);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: left;
  color: var(--black);
    text-transform: uppercase;
}

.personBlurbFont {
  font-family: var(--san-serif-font);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);

}

.headerFont {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  padding-bottom: 10px;
}

