.pageHeader {
  text-align: left;
  font-size: 16px;
  margin-bottom: 20px;
}

.introduction {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.introductionText {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: var(--plum-purple);
}

.countryText {
  display: flex;
  flex: 1;
  font-family: var(--serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: var(--plum-purple);
}

.flexContainer {
  display: flex;
  /*align-items: center;*/
  flex-direction: row;
  margin-bottom: 50px;
  background-color: pink;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flexCol {
  display: flex;
}

.left {
  background-color: red;
  max-width: 30%;
}

.right {
  background-color: blue;
  display: flex;
  flex: 1;
  justify-content: center;
}

.lastCol {
  display: flex;
}

@media only screen and (max-width: 890px) {
  .right {
    margin: 0px;
    flex: 100%;
    justify-content: center;
  }

  .left {
    margin: 0px;
    flex: 100%;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .lastCol {
    display: none;
  }
}

a:hover {
  cursor: pointer;
}

a {
  cursor: pointer;
}
