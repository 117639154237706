.showCaseContainer {
  width: 100%;
  position: relative;
}

/*.slider-image{ background-image:url('the-image.svg'); background-position:center; background-size:100%, auto; }*/

.centerCropped {
  width: 100%;
    height: 80vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("./assets/landingCrop.jpg");
}

.showCaseImage {
  /*display: block;*/
  width: 100%;
  /*width: auto;*/
  /*height: auto;*/
  object-fit: contain;
}

.testLanding {
    position: relative;
    width: 100%;
    overflow-y: hidden;
    /* fallback for old browsers */
    background-image: url("./assets/landingCrop.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 0;
    height: 700px;
    max-height: 85vh;
}



.imgHolderDiv {
  height: 85vh;
  overflow: hidden;
  width: 100%;
  background-color: pink;
  background-image: url("./assets/landingCrop.jpg");
  background-position: center center;
  background-size: 100%, auto;
  background-repeat: no-repeat;
}

.featureFont {
  position: absolute;
  top: 10%;
  left: 8%;
  color: var(--plum-purple);
  font-family: var(--san-serif-font);
  font-size: 37px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
}

@media only screen and (max-width: 790px) {
  .featureFont {
    font-size: 23px;
  }

  .featureFont {
      width: 40%;
      font-size: 45px;
  }
}
