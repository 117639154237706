.baseButton {
    padding: 17px 18px;
    font-family: var(--san-serif-font);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: 2.04px;
    text-align: center;
    background-color: transparent;
    color: var(--black);
    outline: none;
    text-transform: uppercase;
    text-decoration: none;
}

.btnRed {
    border: solid 1px var(--pastel-red);
}

.btnGreen {
    border: solid 1px var(--grey-blue);
}

.btnPurple {
    border: solid 1px var(--plum-purple)
}

.baseButton:hover {
    cursor: pointer;
}

a:hover {
 cursor:pointer;
}