:root {
  --card-max-width: 500px;
}

.spacer {
  margin-top: 100px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
  background-color: rgba(239, 245, 245, 0.7);
}

/*.imgCol {*/
/*  display: flex;*/
/*}*/

/*.image {*/
/*  width: 600px;*/
/*  max-width: 100%;*/
/*}*/

.imgCol {
  display: flex;
  flex: 1 1 auto;
  /*max-height: 90vh;*/
}

.image {
  max-width: 100%;
}

.verticalStack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2 1 300px;
  padding-left: 40px;
  padding-right: 50px;
  /*padding-right: 150px;*/
  /*  margin-left: 50px;*/
}

.ctaBtn {
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
}

.btnSpacer {
  margin-bottom: 20px;
}

.greenBG {
  background-color: rgba(239, 245, 245, 0.7);
}

.redBG {
  background-color: #fef7f6;
}

@media only screen and (max-width: 790px) {
  .verticalStack {
    flex: 1 1 auto;
    padding-bottom: 30px;
    padding-right: 30px;
    justify-content: left;
  }

  /*.image {*/
  /*  width: 100%;*/
  /*  margin: 0 auto;*/
  /*  height: 100%;*/
  /*}*/
  .imageCol {
    justify-content: center;
    align-items: center;
  }
  /*.wrapper :nth-child(1) {order: 2;}*/
  /*.wrapper :nth-child(2) {order: 1;}*/
}

.blurbFont {
  font-family: var(--san-serif-font);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  margin-bottom: 30px;
}

.headerFont {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  padding-bottom: 10px;
}

.containerResponsive {
  max-width: 600px;
  display: flex;
  align-items: center;
  -webkit-justify-content: center;
  /* Safari */
  justify-content: center;
  margin: 0 auto;
}

/*.item {*/
/*  padding: 10px;*/
/*}*/

img {
  width: 100%;
  height: auto;
  display: block;
  /*max-width: 100px;*/
}
