.formBody {
  width: 50%;
  margin: 0 auto;
  border: solid 1px var(--grey-blue);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
}

.verticalStack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 350px;
  margin: 0 auto;
}

.inputField {
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
}

.inputText {
  width: 100%;
  height: 30px;
  border: solid 1px var(--grey-blue);
}

.submitBtnContainer {
  margin: 0 auto;
  width: 100%;
}

.submitBtnContainer button {
  background-color: var(--grey-blue);
  margin: 25px auto 25px auto;
  display: block;
}

@media only screen and (max-width: 790px) {
  .verticalStack {
    /*flex: 1 1 auto;*/
    padding-right: 0px;
    padding-bottom: 30px;
  }

  .formBody {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }

  .inputField {
      width: 90%;
  }

  .wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.formHeaderFont {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  color: var(--grey-blue);
  margin-top: 20px;
  margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.buttonStyle {
  font-family: var(--san-serif-font);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: center;
  color: white;
  padding: 11px 36px;
  text-transform: uppercase;
  border: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}
:-moz-placeholder {
  /* Firefox 18- */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}

input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
  line-height: normal !important;
}

button:hover {
  cursor: pointer;
}


input::placeholder {
  overflow: visible;
}