@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento&display=swap);
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans&display=swap);
:root {
  --pastel-red: #df6057;
  --grey-blue: #619da3;
  --plum-purple: #4d0547;
  --black: #000000;
  --warm-grey: #918e8e;
  --color-heading-size: 20px;
  --san-serif-font: Montserrat, sans-serif;
  --serif-font: Quattrocento, serif;
  --san-serif-Quat: Quattrocento Sans, sans-serif;
  --mobile-breakpoint: 790px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: var(--san-serif-font), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  height: 100vh;
}

html {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}
a[onclick], a:not([name]) { cursor: pointer }

/**************** Layout and structure styles **********************************/
.container-flex {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.top-div {
  margin-top: 100px;
}
/************** Heading Styles ************************/

.landing-header {
  font-family: Montserrat, sans-serif;
  font-family: var(--san-serif-font);
  font-size: 32px;
  font-weight: bold;
  line-height: 1.38;
  text-align: left;
  color: #4d0547;
  color: var(--plum-purple);
}

.color-main-heading {
  opacity: 0.29;
  font-family: Quattrocento, serif;
  font-family: var(--serif-font);
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  text-align: left;
}

.color-sub-heading {
  font-family: Quattrocento, serif;
  font-family: var(--serif-font);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  text-align: left;
}

.small-header {
  height: 16px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: left;
  color: #000000;
  color: var(--black);
  text-transform: uppercase;
}

/* adding this class here again fixes active class not showing when first landing on home page
this class is also defined in the Navbar styles*/
.active {
  font-weight: bold;
}

/***************** Colors *************************************/

.red {
  color: #df6057;
  color: var(--pastel-red);
}

.green {
  color: #619da3;
  color: var(--grey-blue);
}

.purple {
  color: #4d0547;
  color: var(--plum-purple);
}

/*****************************************************/

.DrawerToggleButton_toggleButton__3QWnd {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30px;
    width: 36px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.DrawerToggleButton_toggleButton__3QWnd:focus {
    outline: none;
}

.DrawerToggleButton_toggleButtonLine__1oOeV {
    width: 30px;
    height: 2px;
    background-color: white;
}
.Navbar_spacer__3kWWD {
    flex: 1 1;
}

.Navbar_navbar__2GMgh {
    position: fixed;
    width: 100%;
    background: var(--grey-blue);
    height: 56px;
    top: 0;
    left: 0;
    z-index:1;
}

.Navbar_navbarBody__3rnhU {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.Navbar_navbarLogo__3ESj0 {
    margin-left: 1rem;
}

.Navbar_navbarLogo__3ESj0 a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
}

.Navbar_navbarItems__1HC_t ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.Navbar_navbarItems__1HC_t li {
    padding: 0 0.5rem;
}

.Navbar_navbarItems__1HC_t a {
    color: white;
    text-decoration: none;

}

.Navbar_navbarItems__1HC_t a:hover,
.Navbar_navbarItems__1HC_t a:active {
    font-weight: bold;
}

.Navbar_active__1_PBO {
    font-weight: bold;
}

@media (max-width: 768px) {
    .Navbar_navbarItems__1HC_t {
        display: none;
    }
}

@media (min-width: 769px) {
    .Navbar_navbarToggleButton__tgfe_ {
        display: none;
    }

    .Navbar_navbarLogo__3ESj0 {
        margin-left: 0;
    }
}
.SideDrawer_sideDrawer__FreeR {
  height: 100vh;
  background: var(--grey-blue);
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.SideDrawer_sideDrawerContentContainer__po169 {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.SideDrawer_sideDrawer__FreeR.SideDrawer_open__2bi4y {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.SideDrawer_sideDrawer__FreeR ul {
  list-style: none;
  text-align: left;
  padding-right: 5px;
  padding-top: 50px;
}

.SideDrawer_sideDrawer__FreeR li {
  margin: 0.5rem 0;
  padding-top: 20px;
}

.SideDrawer_sideDrawer__FreeR a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.SideDrawer_sideDrawer__FreeR a:hover,
.SideDrawer_sideDrawer__FreeR a:active {
  color: orange;
  cursor: pointer;
}

.SideDrawer_social__1fLDv {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
  padding-bottom: 80px;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.SideDrawer_iconSize__3PhPu {
  height: 44px;
  width: 44px;
}

@media (min-width: 769px) {
  .SideDrawer_sideDrawer__FreeR {
    display: none;
  }
}

.Backdrop_backdrop__2e5QZ {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 100;
    top: 0;
    left: 0;
}
.Footer_footerContainer__1s-3l {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 265px;
    margin: 100px auto 30px;
}

.Footer_footerItem__3vV4O {
    display: flex;
    padding: 10px;
}

.App_mainContentContainer__1e9Kk {
    position: relative;
    top: 0;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.ShowCaseImage_showCaseContainer__2JcVN {
  width: 100%;
  position: relative;
}

/*.slider-image{ background-image:url('the-image.svg'); background-position:center; background-size:100%, auto; }*/

.ShowCaseImage_centerCropped__yVuWF {
  width: 100%;
    height: 80vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(/static/media/landingCrop.0bf19733.jpg);
}

.ShowCaseImage_showCaseImage__1P4bQ {
  /*display: block;*/
  width: 100%;
  /*width: auto;*/
  /*height: auto;*/
  object-fit: contain;
}

.ShowCaseImage_testLanding__1_gxo {
    position: relative;
    width: 100%;
    overflow-y: hidden;
    /* fallback for old browsers */
    background-image: url(/static/media/landingCrop.0bf19733.jpg);
    background-size: cover;
    background-position: 50% 0;
    height: 700px;
    max-height: 85vh;
}



.ShowCaseImage_imgHolderDiv__1Jnnw {
  height: 85vh;
  overflow: hidden;
  width: 100%;
  background-color: pink;
  background-image: url(/static/media/landingCrop.0bf19733.jpg);
  background-position: center center;
  background-size: 100%, auto;
  background-repeat: no-repeat;
}

.ShowCaseImage_featureFont__1muRF {
  position: absolute;
  top: 10%;
  left: 8%;
  color: var(--plum-purple);
  font-family: var(--san-serif-font);
  font-size: 37px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
}

@media only screen and (max-width: 790px) {
  .ShowCaseImage_featureFont__1muRF {
    font-size: 23px;
  }

  .ShowCaseImage_featureFont__1muRF {
      width: 40%;
      font-size: 45px;
  }
}

/*structural styles*/
.SectionBlurb_wrapper__1niQT {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 80px;
  padding-left: 80px;
}

.SectionBlurb_imgCol__3aA0r {
  display: flex;
  flex: 2 1 450px;
}

.SectionBlurb_image__36wUO {
  max-width: 100%;
  object-fit: contain;
}

.SectionBlurb_verticalStack__weFRM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 300px;
  padding-right: 150px;
}

/*font styles*/

/*.cardLink a {*/
/*  font-family: var(--san-serif-font);*/
/*  font-size: 19px;*/
/*  line-height: 1.67;*/
/*  text-align: left;*/
/*  color: var(--warm-grey);*/
/*  text-decoration: none;*/
/*}*/

.SectionBlurb_cardLink__iM_zZ {
  margin-bottom: 60px;
  margin-top: 30px;
}

.SectionBlurb_headingFont__3TNXf {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: left;
  color: var(--black);
  text-transform: uppercase;
  padding-bottom: 10px;
}

.SectionBlurb_blurbFont__XT0pD {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 10px;
}

@media only screen and (max-width: 790px) {
  .SectionBlurb_verticalStack__weFRM {
    flex: 1 1 auto;
    padding-right: 0px;
    padding-bottom: 30px;
  }
  .SectionBlurb_image__36wUO {
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
  .SectionBlurb_wrapper__1niQT {
    padding-right: 0px;
    padding-left: 0px;
  }
  /*.imageCol {*/
  /*  !*justify-content: center;*!*/
  /*  !*align-self: center;*!*/
  /*}*/
}

.Button_baseButton__2XJ73 {
    padding: 17px 18px;
    font-family: var(--san-serif-font);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: 2.04px;
    text-align: center;
    background-color: transparent;
    color: var(--black);
    outline: none;
    text-transform: uppercase;
    text-decoration: none;
}

.Button_btnRed__3dD_L {
    border: solid 1px var(--pastel-red);
}

.Button_btnGreen__36o35 {
    border: solid 1px var(--grey-blue);
}

.Button_btnPurple__YLF5e {
    border: solid 1px var(--plum-purple)
}

.Button_baseButton__2XJ73:hover {
    cursor: pointer;
}

a:hover {
 cursor:pointer;
}
.Landing_verticalStack__1itTN {
    display: flex;
    flex-direction: column;
}

.Landing_quoteSection__3tNwX {
    position: relative;
    height: 100px;
    background-color: pink;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Landing_quoteHeader__3CWft {
    font-family: var(--san-serif-font);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: 2.04px;
    color: var(--black);
    text-transform: uppercase;
    position: absolute;
    left: 45px;
    top: -5px;
}

.Landing_spacer__p0UhI {
    margin-top: 100px;
}
:root {
  --card-max-width: 500px;
}

.AboutPersonCard_wrapper__2im7n {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}

.AboutPersonCard_imgCol__2Mfpc {
  display: flex;
  flex: 1 1 550px;
}

.AboutPersonCard_image__yMBww {
  max-width: 100%;
}

.AboutPersonCard_verticalStack__2IH4r {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2 1 550px;
  padding-left: 40px;
  padding-right: 40px;
}

.AboutPersonCard_ctaBtn__2f5W4 {
  margin: 0 auto 20px auto;
}

.AboutPersonCard_greenBG__1CcVW {
  background-color: rgba(239, 245, 245, 0.7);
}

.AboutPersonCard_redBG__znCUW {
  background-color: #fef7f6;
}

@media only screen and (max-width: 790px) {
  .AboutPersonCard_verticalStack__2IH4r {
    flex: 1 1 auto;
    padding-bottom: 30px;
    justify-content: left;
  }

  .AboutPersonCard_image__yMBww {
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
  .AboutPersonCard_imageCol__zvv6j {
    justify-content: center;
    align-items: center;
  }
}

.AboutPersonCard_blurbFont__2PGH3 {
  font-family: var(--san-serif-font);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  margin-bottom: 30px;
}

.AboutPersonCard_headerFont__NkzQ- {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  padding-bottom: 10px;
}


.WhoWeAre_spacer__2RsHx {
    margin-top: 100px;
}

.WhoWeAre_divSpacer__SE6Ge {
    padding-top: 40px;
    padding-bottom: 10px;
}

.WhoWeAre_verticalStack__2PRbm {
    display: flex;
    flex-direction: column;
}

.WhoWeAre_btnSpacer__23-FS {
    margin: 50px auto 0 auto;
}

.WhoWeAre_introBlurbFont__2ayOk {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: var(--plum-purple);
  margin-bottom: 60px;
}

.WhoWeAre_personHeaderFont__-NVPt {
  font-family: var(--san-serif-font);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: left;
  color: var(--black);
    text-transform: uppercase;
}

.WhoWeAre_personBlurbFont__318dC {
  font-family: var(--san-serif-font);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);

}

.WhoWeAre_headerFont__3rrpr {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  padding-bottom: 10px;
}


:root {
  --card-max-width: 500px;
}

.WhatWeDo_spacer__16Hp9 {
  margin-top: 100px;
}

.WhatWeDo_wrapper__2DdyT {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
  background-color: rgba(239, 245, 245, 0.7);
}

/*.imgCol {*/
/*  display: flex;*/
/*}*/

/*.image {*/
/*  width: 600px;*/
/*  max-width: 100%;*/
/*}*/

.WhatWeDo_imgCol__3-fIx {
  display: flex;
  flex: 1 1 auto;
  /*max-height: 90vh;*/
}

.WhatWeDo_image__NIiI4 {
  max-width: 100%;
}

.WhatWeDo_verticalStack__nGNQx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2 1 300px;
  padding-left: 40px;
  padding-right: 50px;
  /*padding-right: 150px;*/
  /*  margin-left: 50px;*/
}

.WhatWeDo_ctaBtn__hEdx2 {
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
}

.WhatWeDo_btnSpacer__mrlAU {
  margin-bottom: 20px;
}

.WhatWeDo_greenBG__rIkVS {
  background-color: rgba(239, 245, 245, 0.7);
}

.WhatWeDo_redBG__1eBB_ {
  background-color: #fef7f6;
}

@media only screen and (max-width: 790px) {
  .WhatWeDo_verticalStack__nGNQx {
    flex: 1 1 auto;
    padding-bottom: 30px;
    padding-right: 30px;
    justify-content: left;
  }

  /*.image {*/
  /*  width: 100%;*/
  /*  margin: 0 auto;*/
  /*  height: 100%;*/
  /*}*/
  .WhatWeDo_imageCol__WsI68 {
    justify-content: center;
    align-items: center;
  }
  /*.wrapper :nth-child(1) {order: 2;}*/
  /*.wrapper :nth-child(2) {order: 1;}*/
}

.WhatWeDo_blurbFont__21DmQ {
  font-family: var(--san-serif-font);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  margin-bottom: 30px;
}

.WhatWeDo_headerFont__28YQ9 {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  padding-bottom: 10px;
}

.WhatWeDo_containerResponsive__3dnEQ {
  max-width: 600px;
  display: flex;
  align-items: center;
  /* Safari */
  justify-content: center;
  margin: 0 auto;
}

/*.item {*/
/*  padding: 10px;*/
/*}*/

img {
  width: 100%;
  height: auto;
  display: block;
  /*max-width: 100px;*/
}

:root {
  --card-max-width: 500px;
}

.CountryCard_wrapper__2vFwX {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}

.CountryCard_imgCol__28gZo {
  display: flex;
  flex: 1 1 450px;
}

.CountryCard_image__3h2hy {
  max-width: 100%;
}

.CountryCard_verticalStack__C29Cz {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 300px;
  padding-left: 40px;
  padding-right: 40px;
}

.CountryCard_ctaBtn__Phksp {
  margin: 0 auto 20px auto;
}

.CountryCard_greenBG__nVLoz {
  background-color: rgba(239, 245, 245, 0.7);
}

.CountryCard_redBG__Q3B5f {
  background-color: #fef7f6;
}

@media only screen and (max-width: 790px) {
  .CountryCard_verticalStack__C29Cz {
    flex: 1 1 auto;
    padding-bottom: 30px;
    justify-content: left;
  }

  .CountryCard_image__3h2hy {
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
  .CountryCard_imageCol__1SXXj {
    justify-content: center;
    align-items: center;
  }
}

.CountryCard_blurbFont__3Nc5n {
  font-family: var(--san-serif-font);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  margin-bottom: 30px;
}

.CountryCard_headerFont__2wl1s {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  padding-bottom: 10px;
}

.WhereWeGo_pageHeader__JRGBv {
  text-align: left;
  font-size: 16px;
  margin-bottom: 20px;
}

.WhereWeGo_introduction__3RpmY {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.WhereWeGo_introductionText__36-9D {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: var(--plum-purple);
}

.WhereWeGo_countryText__1LN40 {
  display: flex;
  flex: 1 1;
  font-family: var(--serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: var(--plum-purple);
}

.WhereWeGo_flexContainer__S83og {
  display: flex;
  /*align-items: center;*/
  flex-direction: row;
  margin-bottom: 50px;
  background-color: pink;
}

.WhereWeGo_wrapper__1c-_P {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.WhereWeGo_flexCol__2aSbw {
  display: flex;
}

.WhereWeGo_left__2p3YK {
  background-color: red;
  max-width: 30%;
}

.WhereWeGo_right__3VDpY {
  background-color: blue;
  display: flex;
  flex: 1 1;
  justify-content: center;
}

.WhereWeGo_lastCol__22eKp {
  display: flex;
}

@media only screen and (max-width: 890px) {
  .WhereWeGo_right__3VDpY {
    margin: 0px;
    flex: 100% 1;
    justify-content: center;
  }

  .WhereWeGo_left__2p3YK {
    margin: 0px;
    flex: 100% 1;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .WhereWeGo_lastCol__22eKp {
    display: none;
  }
}

a:hover {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.Itinerary_itineraryContainer__1rVwJ {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.Itinerary_verticalStack__sMQlY {
  display: flex;
  flex-direction: column;
}

.Itinerary_headingFont__rVkFp {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: center;
  color: var(--black);
  text-transform: uppercase;
  padding-bottom: 10px;
}

.Itinerary_subText__2kvao {
  font-family: var(--san-serif-font);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 10px;
    color: var(--grey-blue);
    margin-bottom: 50px;
}

.Itinerary_spaceBelow__1X1mu {
    margin-bottom: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

}
.SampleItineraries_formBody__4gfkR {
  width: 50%;
  margin: 0 auto;
  border: solid 1px var(--grey-blue);
}

.SampleItineraries_wrapper__22mhX {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
}

.SampleItineraries_verticalStack__1k0Cd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 350px;
  margin: 0 auto;
}

.SampleItineraries_inputField__3Lzec {
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
}

.SampleItineraries_inputText__1SXAD {
  width: 100%;
  height: 30px;
  border: solid 1px var(--grey-blue);
}

.SampleItineraries_submitBtnContainer__2gPA2 {
  margin: 0 auto;
  width: 100%;
}

.SampleItineraries_submitBtnContainer__2gPA2 button {
  background-color: var(--grey-blue);
  margin: 25px auto 25px auto;
  display: block;
}

@media only screen and (max-width: 790px) {
  .SampleItineraries_verticalStack__1k0Cd {
    /*flex: 1 1 auto;*/
    padding-right: 0px;
    padding-bottom: 30px;
  }

  .SampleItineraries_formBody__4gfkR {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }

  .SampleItineraries_inputField__3Lzec {
      width: 90%;
  }

  .SampleItineraries_wrapper__22mhX {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.SampleItineraries_formHeaderFont__1UM1E {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  color: var(--grey-blue);
  margin-top: 20px;
  margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.SampleItineraries_buttonStyle__1qHsi {
  font-family: var(--san-serif-font);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: center;
  color: white;
  padding: 11px 36px;
  text-transform: uppercase;
  border: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}

input::-webkit-input-placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
  line-height: normal !important;
}

input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
  line-height: normal !important;
}

button:hover {
  cursor: pointer;
}


input::-webkit-input-placeholder {
  overflow: visible;
}


input::-moz-placeholder {
  overflow: visible;
}


input:-ms-input-placeholder {
  overflow: visible;
}


input::-ms-input-placeholder {
  overflow: visible;
}


input::placeholder {
  overflow: visible;
}
.ContactUsForm_formBody__15rRM {
  width: 80%;
  margin: 0 auto;
  border: solid 1px var(--grey-blue);
}

.ContactUsForm_wrapper__3T2ug {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  /*background-color: red;*/
}

.ContactUsForm_verticalStack__1vyIJ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 250px;
  margin: 0 auto;
  padding-right: 20px;
  /*padding-left: 0px;*/
  /*background-color: pink;*/
}

.ContactUsForm_questionColumn__1ixJC {
  display: flex;
  flex: 1 1 350px;
  height: 100%;
  padding-right: 20px;
  /*background-color: yellow;*/
}

.ContactUsForm_inputField__1jQSt {
  width: 90%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
  /*padding-right: 20px;*/
  /*padding-left: 20px;*/

}

.ContactUsForm_inputText__1hBPQ {
  width: 100%;
  height: 30px;
  border: solid 1px var(--grey-blue);
}

.ContactUsForm_questionBox__3Q1Ad {
  width: 100%;
  border: solid 1px var(--grey-blue);
  height: 200px;
}

.ContactUsForm_submitBtnContainer__3-GnL {
  margin: 0 auto;
  width: 100%;
}

.ContactUsForm_submitBtnContainer__3-GnL button {
  background-color: var(--grey-blue);
  margin: 0 auto;
  display: block;
  margin-bottom: 100px;
  margin-top: 100px;
}

/*media query make fields take whole row*/
/*@media only screen and (max-width: 790px) {*/
/*    .verticalStack {*/
/*        flex: 1 1 auto;*/
/*        padding-right: 0px;*/
/*        padding-bottom: 30px;*/
/*    }*/

/*    .questionColumn {*/
/*        width: 100%;*/
/*        margin: 0 auto;*/
/*        height: 100%;*/
/*    }*/

/*    .wrapper {*/
/*        padding-right: 0px;*/
/*        padding-left: 0px;*/
/*    }*/
/*}*/

.ContactUsForm_formHeaderFont__36i1l {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: center;
  color: var(--grey-blue);
  width: 80%;
  margin: 10px auto 30px auto;

}

.ContactUsForm_buttonStyle__1MCMd {
  font-family: var(--san-serif-font);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: 2.04px;
  text-align: center;
  color: white;
  padding: 11px 36px;
  text-transform: uppercase;
  border: none;
}

button:hover {
  cursor: pointer;
}

/*input[type=text] {*/
/*  border: 1px;*/
/*}*/

/*input::placeholder {*/
/*  font-family: var(--san-serif-font);*/
/*  font-size: 15px;*/
/*  font-weight: normal;*/
/*  font-stretch: normal;*/
/*  font-style: normal;*/
/*  line-height: 1.67;*/
/*  letter-spacing: normal;*/
/*  text-align: left;*/
/*  color: var(--grey-blue);*/
/*}*/

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;
}
::-moz-placeholder { /* Firefox 19+ */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;

}
:-ms-input-placeholder { /* IE 10+ */
  width: 81px;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--grey-blue);
  padding-left: 5px;

}


input::-webkit-input-placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
  line-height: normal !important;
}


input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
  line-height: normal !important;
}


@media only screen and (max-width: 790px) {

  .ContactUsForm_verticalStack__1vyIJ {
    padding-left: 0px;
  }

  .ContactUsForm_formBody__15rRM {
    width: 100%;
  }
}


input::-webkit-input-placeholder {
  overflow: visible;
}


input::-moz-placeholder {
  overflow: visible;
}


input:-ms-input-placeholder {
  overflow: visible;
}


input::-ms-input-placeholder {
  overflow: visible;
}


input::placeholder {
  overflow: visible;
}
