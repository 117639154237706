.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 265px;
    margin: 100px auto 30px;
}

.footerItem {
    display: flex;
    padding: 10px;
}
