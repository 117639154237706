:root {
  --card-max-width: 500px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}

.imgCol {
  display: flex;
  flex: 1 1 550px;
}

.image {
  max-width: 100%;
}

.verticalStack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2 1 550px;
  padding-left: 40px;
  padding-right: 40px;
}

.ctaBtn {
  margin: 0 auto 20px auto;
}

.greenBG {
  background-color: rgba(239, 245, 245, 0.7);
}

.redBG {
  background-color: #fef7f6;
}

@media only screen and (max-width: 790px) {
  .verticalStack {
    flex: 1 1 auto;
    padding-bottom: 30px;
    justify-content: left;
  }

  .image {
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
  .imageCol {
    justify-content: center;
    align-items: center;
  }
}

.blurbFont {
  font-family: var(--san-serif-font);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  margin-bottom: 30px;
}

.headerFont {
  font-family: var(--san-serif-Quat);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 40px;
  padding-bottom: 10px;
}

